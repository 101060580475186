import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import usePreventDecimal from '../../hooks/preventDecimal';
import Select from '../Select/Select';
import FormInputHelperText from '../FormInputHelperText/FormInputHelperText';
import DonationTier from 'client/components/DonationTier/DonationTier';
import { FormattedMessage } from 'react-intl';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    const inputColor = palette.primary.main;
    return {
        root: {
            position: 'relative',
            width: props => props.width,
            display: 'flex',
            flexDirection: 'column',

            '@media (max-width: 525px)': {
                width: 'auto',
            },
        },
        container: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingTop: 14,
            paddingBottom: 14,
            border: `2px solid ${inputColor}`,
            borderRadius: 5,
            background: palette.background.default,
            boxShadow: '0px 3px 6px #00000029',
            boxSizing: 'border-box',

            '@media (max-width: 600px)': {
                paddingTop: 10,
                paddingBottom: 10,
            },

            '[dir=rtl] &': {
                flexDirection: 'row-reverse',
            },
        },
        focused: {
            outline: `2px solid ${inputColor}`,
        },
        input: {
            paddingTop: 1,
            paddingBottom: 1,
            paddingInlineStart: spacing(2),
            paddingInlineEnd: spacing(1),
            boxSizing: 'border-box',
            border: 'none',
            color: inputColor,
            fontFamily: typography.fontFamily,
            fontWeight: 500,
            fontSize: 22,
            textAlign: 'right',
            flexGrow: 1,
            flexShrink: 1,
            minWidth: spacing(2),
            width: 0,
            '&:focus': {
                border: 'none',
                outline: 'none',
            },
            '-moz-appearance': 'textfield',
            '&::-webkit-input-placeholder': {
                color: inputColor,
                textAlign: 'start',
            },
            '&::-moz-placeholder': {
                color: inputColor,
                textAlign: 'start',
            },
            '&:-moz-placeholder': {
                color: inputColor,
                textAlign: 'start',
            },
            '&:-ms-input-placeholder': {
                color: inputColor,
                textAlign: 'start',
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            ':not(html[dir=rtl]) &': {
                paddingInlineEnd: ({ hasValue }) => (hasValue ? 0 : spacing(2)),

                '@media (max-width: 600px)': {
                    paddingInlineEnd: ({ hasValue }) =>
                        hasValue ? 0 : spacing(2),
                },

                '@media (max-width: 400px)': {
                    paddingInlineEnd: ({ hasValue }) =>
                        hasValue ? 0 : spacing(1),
                },
            },
            'html[dir=rtl] &': {
                order: 2,

                paddingInlineStart: ({ hasValue }) =>
                    hasValue ? 0 : spacing(2),

                '@media (max-width: 600px)': {
                    paddingInlineStart: ({ hasValue }) =>
                        hasValue ? 0 : spacing(2),
                },

                '@media (max-width: 400px)': {
                    paddingInlineStart: ({ hasValue }) =>
                        hasValue ? 0 : spacing(1),
                },
            },
            '@media (max-width: 600px)': {
                fontSize: 17,
                fontWeight: 400,
                paddingInlineEnd: '15px',
            },
            '@media (max-width: 400px)': {
                paddingInlineStart: spacing(1),
                paddingInlineEnd: spacing(1),
            },
        },
        singleCurrency: {
            marginInlineStart: spacing(2),
            border: `2px solid ${inputColor}`,
            borderRadius: 16,
            overflow: 'visible',
            fontSize: 15,
            height: 26,
            color: inputColor,
            padding: '5px 15px 0 15px',
            flexShrink: 0,

            'html[dir=rtl] &': {
                order: 4,
            },
        },
        select: {
            marginInlineStart: spacing(2),
            border: `2px solid ${inputColor}`,
            borderRadius: 16,
            overflow: 'visible',
            flexShrink: 0,

            '& select': {
                fontSize: 15,
                height: 30,
                color: inputColor,
                padding: '0 35px 0 15px',
            },
            '&::before': {
                left: 'auto !important',
                right: '-2px !important',
                backgroundColor: changeColorAlpha(palette.primary.light, 0.2),
                background: `linear-gradient(0deg, ${changeColorAlpha(
                    palette.primary.light,
                    0.2,
                )} 0%, ${changeColorAlpha(palette.primary.dark, 0.4)} 100%)`,
                border: 'none',
                borderLeft: `2px solid ${inputColor}`,
                borderRadius: '0 16px 16px 0',
            },
            '&::after': {
                borderColor: `${inputColor}`,
                right: '11px !important',
                left: 'auto !important',
            },

            'html[dir=rtl] &': {
                left: 'auto',
                order: 4,
            },

            '@media (max-width: 600px)': {
                '& select': {
                    fontSize: 13,
                    height: 20,
                    color: inputColor,
                    transform: 'translateY(-10%)',
                },
            },

            '@media (max-width: 400px)': {
                marginInlineStart: spacing(1),
            },
        },
        suffix: {
            color: inputColor,
            fontFamily: typography.fontFamily,
            fontWeight: 500,
            fontSize: 22,
            direction: 'ltr',
            flexShrink: 0,
            paddingInlineEnd: spacing(2),

            'html[dir=rtl] &': {
                paddingRight: 12,
                order: 3,
            },

            '@media (max-width: 600px)': {
                fontSize: 17,
                fontWeight: 400,
            },

            '@media (max-width: 400px)': {
                paddingInlineEnd: spacing(1),
            },
        },
        suffixWithDonationTier: {
            paddingInlineEnd: 0,
        },
        tooltip: {
            color: inputColor,
            fontWeight: 900,
            padding: spacing(1),
        },
    };
});

function MakeDonationInput(props) {
    const {
        options = [],
        className,
        placeholder = '',
        width = 'auto',
        selectProps,
        value,
        donationTier,
        ...rest
    } = props;
    const classes = useStyles({ width, hasValue: !!value });
    const [handleKeyDown, ignoreChange] = usePreventDecimal();
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={classNames(classes.root, className)}>
            <div
                className={classNames(
                    classes.container,
                    isFocused ? classes.focused : null,
                )}
            >
                {options.length > 1 && (
                    <Select
                        className={classes.select}
                        {...selectProps}
                        options={options}
                        disabled={options.length <= 1}
                    />
                )}

                {options.length === 1 && (
                    <div className={classes.singleCurrency}>
                        {options[0].name}
                    </div>
                )}
                <input
                    type="text"
                    inputMode="numeric"
                    placeholder={placeholder}
                    className={classes.input}
                    value={value ? value?.toLocaleString('en-EN') : ''}
                    {...rest}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onKeyDown={handleKeyDown}
                />
                {!!value && (
                    <span
                        className={classNames(
                            classes.suffix,
                            donationTier
                                ? classes.suffixWithDonationTier
                                : null,
                        )}
                    >
                        .00
                    </span>
                )}
                {Boolean(donationTier) && (
                    <DonationTier donationTier={donationTier} />
                )}
            </div>
            {ignoreChange && (
                <FormInputHelperText className={classes.tooltip}>
                    <FormattedMessage
                        id="EnterAmountInput.decimalTooltip"
                        defaultMessage="Decimals are not allowed in donation amount"
                    />
                </FormInputHelperText>
            )}
        </div>
    );
}

MakeDonationInput.propTypes = {
    options: PropTypes.array,
    placeholder: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectProps: PropTypes.object,
    donationTier: PropTypes.object,
    className: PropTypes.string,
    value: PropTypes.string,
};

export default MakeDonationInput;
