import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import usePreventDecimal from '../../hooks/preventDecimal';
import Select from '../Select/Select';
import FormInputHelperText from '../FormInputHelperText/FormInputHelperText';
import { FormattedMessage } from 'react-intl';
import DonationTier from 'client/components/DonationTier/DonationTier';
import classNames from 'classnames';
import Card from 'client/componentLibrary/Card';

const useStyles = makeStyles(({ palette, spacing, typography, zIndex }) => ({
    root: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        position: 'relative',
        width: '100%',

        display: 'flex',
        alignItems: 'center',
        background: palette.background.default,
        boxSizing: 'border-box',
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        borderRadius: spacing(3),
        '[dir=rtl] &': {
            flexDirection: 'row-reverse',
        },
    },
    withPrimaryBorder: {
        borderRadius: spacing(2),
        border: `2px solid ${palette.primary.main}`,
    },
    focusedPrimary: {
        outline: `0.5px solid ${palette.primary.main}`,
    },
    input: {
        boxSizing: 'border-box',
        border: `none`,
        borderRadius: spacing(2),
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontWeight: 400,
        fontSize: typography.pxToRem(22),
        textAlign: 'right',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: spacing(2),
        width: 0,
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
        '-moz-appearance': 'textfield',
        '&::-webkit-input-placeholder': {
            color: palette.text.primary,
            textAlign: 'start',
        },
        '&::-moz-placeholder': {
            color: palette.text.primary,
            textAlign: 'start',
        },
        '&:-moz-placeholder': {
            color: palette.text.primary,
            textAlign: 'start',
        },
        '&:-ms-input-placeholder': {
            color: palette.text.primary,
            textAlign: 'start',
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        ':not(html[dir=rtl]) &': {
            paddingInlineEnd: spacing(2),

            '@media (max-width: 600px)': {
                paddingInlineEnd: spacing(2),
            },

            '@media (max-width: 400px)': {
                paddingInlineEnd: spacing(1),
            },
        },
        'html[dir=rtl] &': {
            order: 2,

            paddingInlineStart: spacing(2),

            '@media (max-width: 600px)': {
                paddingInlineStart: spacing(2),
            },

            '@media (max-width: 400px)': {
                paddingInlineStart: spacing(1),
            },
        },
        '@media (max-width: 600px)': {
            fontSize: '1rem',
            fontWeight: 400,
            paddingInlineEnd: spacing(2),
        },
        '@media (max-width: 400px)': {
            paddingInlineStart: 0,
            paddingInlineEnd: spacing(1),
        },
    },
    inputWithValue: {
        borderRadius: 0,
        ':not(html[dir=rtl]) &': {
            paddingInlineEnd: 0,

            '@media (max-width: 600px)': {
                paddingInlineEnd: 0,
            },

            '@media (max-width: 400px)': {
                paddingInlineEnd: 0,
            },
        },
        'html[dir=rtl] &': {
            paddingInlineStart: 0,

            '@media (max-width: 600px)': {
                paddingInlineStart: 0,
            },

            '@media (max-width: 400px)': {
                paddingInlineStart: 0,
            },
        },
    },
    singleCurrency: {
        marginInlineStart: spacing(1),
        borderRadius: 16,
        overflow: 'visible',
        fontSize: '1rem',
        height: 26,
        color: palette.text.primary,
        padding: '5px 15px 0 15px',
        flexShrink: 0,
        position: 'relative',

        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            bottom: 0,
            background: '#fff',
            pointerEvents: 'none',
            left: 'auto !important',
            right: `${spacing(0.5)}px !important`,
            border: 'none',
            borderLeft: `1px solid ${palette.text.primary}`,
            width: 'auto',
        },

        'html[dir=rtl] &': {
            order: 4,

            '&::before': {
                right: 'auto !important',
                left: `${spacing(0.5)}px !important`,
            },
        },
    },
    select: {
        marginInlineStart: spacing(1),
        border: `none`,
        borderRadius: 16,
        overflow: 'visible',
        flexShrink: 0,

        '& select': {
            fontSize: '1rem',
            color: palette.text.primary,
        },
        '&::before': {
            left: 'auto !important',
            right: `${spacing(0.5)}px !important`,
            border: 'none',
            borderLeft: `1px solid ${palette.text.primary}`,
            width: 'auto',
        },
        '&::after': {
            borderColor: `${palette.text.primary}`,
            width: spacing(0.75),
            height: spacing(0.75),
            right: `${spacing(3)}px !important`,
            left: 'auto !important',
        },

        'html[dir=rtl] &': {
            left: 'auto',
            order: 4,
            '&::before': {
                right: 'auto !important',
                left: `${spacing(0.5)}px !important`,
            },
            '&::after': {
                right: 'auto !important',
                left: `${spacing(3)}px !important`,
            },
        },

        '@media (max-width: 600px)': {
            '& select': {
                fontSize: typography.pxToRem(14),
                paddingInlineStart: spacing(1),
            },
        },
    },
    suffix: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontWeight: 400,
        fontSize: typography.pxToRem(22),
        direction: 'ltr',
        flexShrink: 0,
        paddingInlineEnd: spacing(2),

        'html[dir=rtl] &': {
            order: 3,
        },

        '@media (max-width: 600px)': {
            fontSize: '1rem',
            fontWeight: 400,
            top: '50%',
        },

        '@media (max-width: 400px)': {
            paddingInlineEnd: spacing(1),
        },
    },
    suffixWithDonationTier: {
        paddingInlineEnd: spacing(0.5),
    },
    tooltip: {
        position: 'absolute',
        color: palette.text.primary,
        fontWeight: 900,
        padding: spacing(1),
        top: `calc(100% + ${spacing(1)}px)`,
        left: '50%',
        zIndex: zIndex.tooltip,
        'html[dir=rtl] &': {
            left: 'auto',
        },
    },
}));

function MinimalDonationInput(props) {
    const {
        options = [],
        className,
        placeholder = '',
        selectProps,
        value,
        donationTier,
        withPrimaryBorder = false,
        ...rest
    } = props;
    const classes = useStyles();
    const [handleKeyDown, ignoreChange] = usePreventDecimal();
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={classNames(classes.root, className)}>
            <div
                className={classNames(classes.container, {
                    [classes.withPrimaryBorder]: withPrimaryBorder,
                    [classes.focusedPrimary]: isFocused && withPrimaryBorder,
                })}
            >
                {options.length > 1 && (
                    <Select
                        className={classes.select}
                        {...selectProps}
                        options={options}
                        disabled={options.length <= 1}
                    />
                )}

                {options.length === 1 && (
                    <div className={classes.singleCurrency}>
                        {options[0].name}
                    </div>
                )}
                <input
                    type="text"
                    inputMode="numeric"
                    placeholder={placeholder}
                    className={classNames(classes.input, {
                        [classes.inputWithValue]: !!value,
                    })}
                    value={value ? value?.toLocaleString('en-EN') : ''}
                    {...rest}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onKeyDown={handleKeyDown}
                />
                {!!value && (
                    <span
                        className={classNames(
                            classes.suffix,
                            donationTier
                                ? classes.suffixWithDonationTier
                                : null,
                        )}
                    >
                        .00
                    </span>
                )}
                {Boolean(donationTier) && (
                    <DonationTier donationTier={donationTier} />
                )}
            </div>
            {ignoreChange && (
                <Card className={classes.tooltip}>
                    <FormInputHelperText>
                        <FormattedMessage
                            id="EnterAmountInput.decimalTooltip"
                            defaultMessage="Decimals are not allowed in donation amount"
                        />
                    </FormInputHelperText>
                </Card>
            )}
        </div>
    );
}

MinimalDonationInput.propTypes = {
    className: PropTypes.string,
    donationTier: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    selectProps: PropTypes.object,
    selectWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string,
    withPrimaryBorder: PropTypes.bool,
};

export default MinimalDonationInput;
