import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    standard: {
        objectFit: 'cover',
    },
    rounded: {
        borderRadius: spacing(3),
        '-webkit-border-radius': `${spacing(3)}px`,
        '-moz-border-radius': `${spacing(3)}px`,
    },
}));

function HeroBanner({ className, src, height, variant = 'standard', ...rest }) {
    const classes = useStyles();

    return src ? (
        <div
            style={{ height }}
            className={classNames('hero-banner', className)}
        >
            <img
                className={classNames(classes.root, {
                    [classes.standard]: variant === 'standard',
                    [classes.rounded]: variant === 'rounded',
                })}
                src={src}
                alt={src}
                {...rest}
            />
        </div>
    ) : null;
}

HeroBanner.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variant: PropTypes.oneOf(['standard', 'topRounded', 'rounded']),
};

export default React.memo(
    HeroBanner,
    (current, next) =>
        current.src === next.src && current.height === next.height,
);
